@import '../../../../scss/theme-bootstrap';

.split-width-logo-sticky {
  margin-#{$rdirection}: auto;
  margin-#{$ldirection}: auto;
  position: relative;
  max-width: $container-max-width-landscape;
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 0;
    max-width: $container-max-width-landscape;
    position: relative;
  }
  img,
  picture,
  video {
    max-width: 100%;
    height: auto;
  }
  &__initial-section-wrapper {
    @include breakpoint($medium-up) {
      padding-top: 120px;
      margin-#{$ldirection}: 40px;
      margin-#{$rdirection}: 40px;
      @include breakpoint($med-lrg-height-up) {
        max-height: 900px;
      }
    }
  }
  &__logo {
    position: relative;
    margin: 100px 15px 15px;
    @include breakpoint($medium-up) {
      position: fixed;
      margin: 0;
      bottom: 40px;
      width: calc(50% - 80px);
      &--scrolled {
        position: absolute;
        bottom: 0;
      }
      &--hide {
        display: none;
      }
    }
  }
  &__column--content {
    width: 100%;
    margin: 0;
    padding: 0;
    &-wrapper {
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
  }
  &__column--media {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      padding: 0;
      max-width: 720px;
      z-index: 994;
      cursor: pointer;
      width: 100%;
      margin: 0 auto;
      position: relative;
      @include breakpoint($med-lrg-height-up) {
        max-height: 900px;
      }
    }
    .split-width-logo-sticky__wrapper--scrolled & {
      position: relative;
      bottom: 0;
      @include breakpoint($medium-up) {
        position: absolute;
      }
    }
    .split-width-logo-sticky__media {
      img {
        width: 100%;
        object-fit: cover;
        @include breakpoint($med-lrg-height-up) {
          max-height: 900px;
        }
      }
    }
  }
  &__content-before-logo {
    margin: 20px;
    @include breakpoint($medium-up) {
      margin: 0;
    }
    &--text {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  &__mobile--media {
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .content-block__line {
    padding-bottom: unset;
  }
}
